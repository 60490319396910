import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueSocketIO from "vue-3-socket.io";
import SocketIO from "socket.io-client";

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(
    new VueSocketIO({
      debug: true,
      connection: SocketIO("https://lostark.cifer.de:1337"),
      // connection: SocketIO("http://cifer.lan:1337"),
    })
  )
  .mount("#app");
