<template>
  <v-app :theme="theme">
    <v-app-bar>
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Lost Ark - Server Status</v-app-bar-title>
      <span v-if="steamChartData && steamChartData.latest" style="user-select: none;">
        <p @click="this.$socket.emit('steamCharts', { ID: 1599340 })">
          Current Playercount: <b>{{steamChartData.latest[1]}}</b>
          <small>
            - {{ new Date(steamChartData.latest[0]).toLocaleDateString(undefined, { day: 'numeric', month: 'long', hour: 'numeric' }) }} |
            last updated {{ new Date().toLocaleDateString(undefined, { hour: 'numeric', minute: 'numeric', second: 'numeric' }) }}
          </small>
        </p>
      </span>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      bottom
      temporary
    >
      <v-list>
        <v-list-item
          v-for="(region, index) in regions"
          :href="'/#/' + region.value"
          :key="index"
          :title="region.title"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="justify-center">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  title: 'Lost Ark Status',
  data: () => ({
    theme: 'dark',
    drawer: false,
    group: null,
    regions: [
      { title: "WEST NORTH AMERICA", value: "wna" },
      { title: "EAST NORTH AMERICA", value: "ena" },
      { title: "CENTRAL EUROPE", value: "ceu" },
      { title: "SOUTH AMERICA", value: "sa" },
      { title: "EUROPE WEST", value: "euw" },
    ],
    steamChartData: undefined
  }),
  sockets: {
    charts: function(data) {
      if(data.appID = 1599340) {
        this.steamChartData = data;
      }
    }
  }
}
</script>
