<template>
    <span :class="classList">
        {{ serverName }} - {{ serverStatus }}
    </span>
</template>
<script>
export default {
    name: 'ServerStatusElement',
    props: {
        serverName: {
            type: String,
            required: true
        },
        serverStatus: {
            type: String,
            required: true
        }
    },
    computed: {
        classList() {
            return ['status-element', 'server-status-element', `status-${this.serverStatus}`]
        }
    }
}
</script>
<style scoped>
.status-full {
    color: teal;
}
.status-busy {
    color: red;
}
.status-good {
    color: green;
}
.status-maintenance {
    color: orange;
}
.status-element {
    flex: 1 0 24%;
}
</style>