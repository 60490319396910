<template>
  <RegionStatusPage :region="region" :realm="realm" :data="regionData" :statusUpdate="lastStatusUpdate" />
</template>

<script>
import RegionStatusPage from '../components/RegionStatusPage.vue'
export default {
    name: 'StatusPage',
    components: {
      RegionStatusPage
    },

    data() {
      return {
        region: 'ceu',
        realm: undefined,
        realmLastState: undefined,
        regionData: { },
        lastStatusUpdate: '',
        permission: false,
      }
    },

    watch: {
    },

    sockets: {
      connect: function() {
        this.$socket.on('reconnect', () => {
          this.initSocketIO()
        })
        this.initSocketIO();
      },
      regionStatusData: function(data) {
        if(data && data.id) {
          if(data.id == 'lastServerUpdate') this.lastStatusUpdate = data.data
          else {
            this.regionData = data.data
            if(this.permission === 'granted') {
              if(this.realm && this.realmLastState) {
                const newState = data.data[this.realm]
                if(newState != this.realmLastState)
                  new Notification(`The Status of Realm ${this.realm} changed from ${this.realmLastState} to ${newState}`)
              }
            }
            if(this.realm) this.realmLastState = data.data[this.realm] || undefined
            else this.realmLastState = undefined
          }
        }
      }
    },
    beforeMount() {
      this.region = this.$route.params.region
      this.realm = this.$route.params.realm || undefined
    },
    async created() {
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          if(toParams && toParams.region) {
            this.$socket.emit('unsubscribeRegion', previousParams.region);
            this.region = toParams.region
            this.$socket.emit('subscribeRegion', this.region);
          }
          this.realm = toParams.realm || undefined;
        }
      )
      this.permission = await Notification.requestPermission();
    },
    mounted() {
    },
    methods: {
      initSocketIO() {
        this.$socket.emit('subscribeServerUpdate');
        this.$socket.emit('subscribeRegion', this.region);
        this.$socket.on('regionStatusData', this.regionStatusData);
      },
    }
  };
</script>
<style scoped>
.no-select {
  user-select: none;
}
</style>
