import { createRouter, createWebHashHistory } from "vue-router";
import StatusPage from "../views/StatusPage.vue";

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return "/ceu/Antares";
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/:region",
    name: "ServerStatus",
    component: StatusPage,
  },
  {
    path: "/:region/:realm",
    name: "ServerStatusWithRealm",
    component: StatusPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
