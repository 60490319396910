<template>
    <div class="justify-center">
        <v-container>
            <v-row>
                <v-col cols="12" md="6" xs="12">
                    <v-card>
                        <v-card-title>{{ fullRegionName }} - {{ statusUpdate }}</v-card-title>
                        <v-card-text>
                            <div class="server-status-box">
                                <server-status-element
                                    v-for="(status, name) of data"
                                    :key="name"
                                    :serverName="name"
                                    :serverStatus="status" />
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" xs="12" v-if="realm">                        
                    <v-card >
                        <v-card-title>{{ realm }}</v-card-title>
                        <v-card-text>
                            <p v-for="(history, index) of watchHistory" :key="index">
                                <server-status-element
                                    :serverName="history.time"
                                    :serverStatus="history.status" />
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>


    </div>
</template>
<script>
import ServerStatusElement from '../components/ServerStatusElement.vue'
export default {
    name: 'RegionStatusPage',
    components: {
      ServerStatusElement
    },
    props: {
        region: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        statusUpdate: {
            type: String,
            required: true
        },
        realm: {
            type: String,
            default: undefined
        }
    },
    watch: {
        data(toParams, previousParams) {
            if(this.realm && Object.keys(toParams).indexOf(this.realm) !== -1) {
                if(this.watchHistory.length > 10) this.watchHistory.shift()
                this.watchHistory.push({
                    time: new Date().toString(),
                    status: toParams[this.realm]
                });
            }
        }
    },
    data() {
        return ({
            watchHistory: [],
            lastWatchStatus: undefined
        })
    },
    computed: {
        fullRegionName() {
            switch(this.region) {
                case 'ceu': return 'Central Europe'
                case 'wna': return 'West North America'
                case 'ena': return 'East North America'
                case 'sa': return 'South North America'
                default: return this.region
            }
        }
    }
}
</script>
<style scoped>
.server-status-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
}
.server-status-element {
}
</style>